export const menuReducer = (
  state = {
    thisAvailableMenus: [],
  },
  action
) => {
  switch (action.type) {
    case "SET_AVAILABLE_MENUS": {
      action.payload.forEach((item, index) => {
        let key = action.payload[index]._id
        state = {
          ...state,
          [key]: {
            thisMenuItems: [],
            thisCategories: [],
            thisTotalMenuItems: 0,
          },
        }
      })
      return {
        ...state,
        thisAvailableMenus: action.payload,
      }
    }
    case "SET_MENU_ITEMS": {
      state[action.payload.activeMenuId].thisMenuItems = action.payload.menuItems
      return state
    }
    case "SET_CATEGORIES": {
      state[action.payload.activeMenuId].thisCategories = action.payload.categories
      return state
    }
    case "SET_TOTAL_NUMBER_MENU_ITEMS":
      state[action.payload.activeMenuId].thisTotalMenuItems =
        action.payload.totalMenuItems
      return state
    default:
      return state
  }
}
