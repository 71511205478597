const initialState = {
  thisCustomer: null,
  paymentIntentId: "",
  paymentMethodId: "",
  addToBill: false,
}

const stripeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INTENT_PAYMENT_ID":
      return {
        ...state,
        paymentIntentId: action.payload,
      }
    case "SET_PAYMENT_METHOD_ID":
      return {
        ...state,
        paymentMethodId: action.payload,
      }
    case "ADD_TO_BILL":
      return {
        ...state,
        addToBill: action.payload,
      }
    case "SET_STRIPE_CUSTOMER":
      return {
        ...state,
        thisCustomer: action.payload,
      }
    default:
      return state
  }
}

export default stripeReducer
