import { Button, IconButton, Snackbar } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import React, { useEffect, useState } from "react"

function AlertMessage({ alertMessage, alertChange }) {
  const [alertOpen, setAlertOpen] = useState(true)

  useEffect(() => {
    if (alertMessage) setAlertOpen(true)
    else setAlertOpen(false)
  }, [alertMessage])

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => setAlertOpen(false)}
        message={alertMessage}
        action={
          <>
            <Button
              color="secondary"
              size="small"
              onClick={() => setAlertOpen(false)}
            >
              UNDO
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setAlertOpen(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </div>
  )
}

export default AlertMessage
