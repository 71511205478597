const initialState = {
  restaurant: null,
  loading: true,
  currencySign: "",
  instructions: [],
}

const restaurantReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RESTAURANT": {
      return {
        ...state,
        restaurant: action.payload,
        loading: false,
      }
    }
    case "UPDATE_RESTAURANT":
      return {
        ...state,
        restaurant: { ...state.restaurant, dinifySidePayments: action.payload },
        loading: false,
      }
    case "SET_CURRENCY_SYMBOL":
      return {
        ...state,
        currencySign: action.payload,
      }
    case "SET_INSTRUCTIONS": {
      return {
        ...state,
        instructions: action.payload,
      }
    }
    default:
      return state
  }
}
export default restaurantReducer
