export const supportedLangs = [
  { name: "Afrikaans", languageCode: "af" },
  { name: "Albanian", languageCode: "sq" },
  { name: "Amharic", languageCode: "am" },
  { name: "Arabic", languageCode: "ar" },
  { name: "Armenian", languageCode: "hy" },
  { name: "Azerbaijani", languageCode: "az" },
  { name: "Bengali", languageCode: "bn" },
  { name: "Bosnian", languageCode: "bs" },
  { name: "Bulgarian", languageCode: "bg" },
  { name: "Catalan", languageCode: "ca" },
  { name: "Chinese (Simplified)", languageCode: "zh" },
  { name: "Chinese (Traditional)", languageCode: "zh-TW" },
  { name: "Croatian", languageCode: "hr" },
  { name: "Czech", languageCode: "cs" },
  { name: "Danish", languageCode: "da" },
  { name: "Dari", languageCode: "fa-AF" },
  { name: "Dutch", languageCode: "nl" },
  { name: "English", languageCode: "en" },
  { name: "Estonian", languageCode: "et" },
  { name: "Farsi (Persian)", languageCode: "fa" },
  { name: "Filipino Tagalog", languageCode: "tl" },
  { name: "Finnish", languageCode: "fi" },
  { name: "French", languageCode: "fr" },
  { name: "French (Canada)", languageCode: "fr-CA" },
  { name: "Georgian", languageCode: "ka" },
  { name: "German", languageCode: "de" },
  { name: "Greek", languageCode: "el" },
  { name: "Gujarati", languageCode: "gu" },
  { name: "Haitian", languageCode: "Creole	ht" },
  { name: "Hausa", languageCode: "ha" },
  { name: "Hebrew", languageCode: "he" },
  { name: "Hindi", languageCode: "hi" },
  { name: "Hungarian", languageCode: "hu" },
  { name: "Icelandic", languageCode: "is" },
  { name: "Indonesian", languageCode: "id" },
  { name: "Irish", languageCode: "ga" },
  { name: "Italian", languageCode: "it" },
  { name: "Japanese", languageCode: "ja" },
  { name: "Kannada", languageCode: "kn" },
  { name: "Kazakh", languageCode: "kk" },
  { name: "Korean", languageCode: "ko" },
  { name: "Latvian", languageCode: "lv" },
  { name: "Lithuanian", languageCode: "lt" },
  { name: "Macedonian", languageCode: "mk" },
  { name: "Malay", languageCode: "ms" },
  { name: "Malayalam", languageCode: "ml" },
  { name: "Maltese", languageCode: "mt" },
  { name: "Marathi", languageCode: "mr" },
  { name: "Mongolian", languageCode: "mn" },
  { name: "Norwegian", languageCode: "no" },
  { name: "Pashto", languageCode: "ps" },
  { name: "Polish", languageCode: "pl" },
  { name: "Portuguese (Brazil)", languageCode: "pt" },
  { name: "Portuguese (Portugal)", languageCode: "pt-PT" },
  { name: "Punjabi", languageCode: "pa" },
  { name: "Romanian", languageCode: "ro" },
  { name: "Russian", languageCode: "ru" },
  { name: "Serbian", languageCode: "sr" },
  { name: "Sinhala", languageCode: "si" },
  { name: "Slovak", languageCode: "sk" },
  { name: "Slovenian", languageCode: "sl" },
  { name: "Somali", languageCode: "so" },
  { name: "Spanish", languageCode: "es" },
  { name: "Spanish (Mexico)", languageCode: "es-MX" },
  { name: "Swahili", languageCode: "sw" },
  { name: "Swedish", languageCode: "sv" },
  { name: "Tamil", languageCode: "ta" },
  { name: "Telugu", languageCode: "te" },
  { name: "Thai", languageCode: "th" },
  { name: "Turkish", languageCode: "tr" },
  { name: "Ukrainian", languageCode: "uk" },
  { name: "Urdu", languageCode: "ur" },
  { name: "Uzbek", languageCode: "uz" },
  { name: "Vietnamese", languageCode: "vi" },
  { name: "Welsh", languageCode: "cy" },
]

export const Toursteps = [
  {
    element: ".addItems",
    intro: "You can add more items from here",
    position: "bottom",
    tooltipClass: "myTooltipClass",
    highlightClass: "myHighlightClass",
  },
  {
    element: ".viewmenubutton",
    intro: "You can edit or delete item from here.",
  },
  {
    element: ".tipbutton",
    intro: "Select tip percent or enter custom tip here.",
  },
  {
    element: ".grandtotal",
    intro: "You can see the bill details here.",
  },
  {
    element: ".ordername",
    intro: "Add your order name here.",
  },
  {
    element: ".email",
    intro: "Add your emailId here to receive payment receipt.",
  },
  {
    element: ".cardbutton",
    intro: "Edit your payment method here.",
    position: "bottom",
  },
  {
    element: ".billbutton",
    intro: "Start bill from here.",
  },
]
