import { Box, Button, LinearProgress, Typography } from "@material-ui/core"
import PaymentIcon from "@material-ui/icons/Payment"
import { makeStyles } from "@material-ui/styles"
import React from "react"
const useStyles = makeStyles((theme) => ({
  card: {
    marginRight: "10px",
  },
  alert: {
    display: "flex",
    width: "90%",
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  success: {
    display: "flex",
    width: "90%",
    color: "green",
    padding: theme.spacing(2),
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  cardWrapper: {
    padding: "10px 5px",
    borderRadius: "5px",
  },
  cardContainer: {
    padding: "10px 5px",
    width: "100%",
  },
}))

function CustomCard({
  isCardAdded,
  prevLast4,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  prevPaymentMethodId,
  deleteLoading,
  setDeleteLoading,
  handleIsDelete,
  isLoading,
  cardElement,
  tab,
}) {
  const classes = useStyles()

  return (
    <Box style={{ width: "100%" }}>
      {isCardAdded ? (
        <Box className={classes.cardContainer}>
          {prevLast4 ? (
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ p: 2 }}
              >
                <Box display="flex" alignItems="center" sx={{ my: 2 }}>
                  <Box className={classes.card}>
                    <PaymentIcon fontSize="medium" />
                  </Box>
                  <Typography variant="body1">**** **** {prevLast4}</Typography>
                </Box>

                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  disabled={deleteLoading}
                  onClick={() => {
                    setDeleteLoading(true)
                    handleIsDelete()
                  }}
                >
                  DELETE CARD
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ mt: 2 }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
      ) : (
        <Box>
          <Box
            style={{
              opacity: `${isCardAdded ? "0" : "1"}`,
              pointerEvents: `${isLoading ? "none" : ""}`,
              height: `${isCardAdded ? "0" : ""}`,
            }}
          >
            {cardElement ? cardElement : <h3>Loading...</h3>}
            {isLoading && <LinearProgress />}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default CustomCard
