let initialState = { orders: [], reload: false, thisOrder: null }
export const orderListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ORDER":
      return {
        ...state,
        thisOrder: action.payload,
      }
    case "REFRESH":
      return {
        ...state,
        reload: !state.reload,
      }
    case "SET_INITIAL_ORDER_STATE":
      return initialState
    default:
      return state
  }
}
