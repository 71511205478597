import axios from "../axios"

export const setRestaurant = (id) => async (dispatch) => {
  try {
    const restaurant = await axios.get(`/user/restaurant-details/${id}`)
    dispatch({ type: "SET_RESTAURANT", payload: restaurant.data })
  } catch (error) {
    console.log(error)
  }
}
