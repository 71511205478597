import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  TextField,
  Typography,
  Slide,
} from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { createTheme } from "@material-ui/core/styles"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import SearchIcon from "@material-ui/icons/Search"
import TranslateIcon from "@material-ui/icons/Translate"
import TuneIcon from "@material-ui/icons/Tune"
import { Skeleton } from "@material-ui/lab"
import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import axios from "../axios"
import { supportedLangs } from "../utils/Data.js"
import { makeDecrypt, makeEncrypt } from "../utils/securels"

const useStyles = makeStyles((theme) => ({
  container: {
    fontWeight: "900",
    marginTop: "10px",
    padding: "10px 10px 0px 10px",
    position: "relative",
    zIndex: "100",
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 1,
    textAlign: "center",
  },
  icon: {
    position: "absolute",
    right: "10px",
  },
  IconsHeader: {},
  search: {
    display: "flex",
    width: "100%",
  },
  input: {
    borderRadius: "40px",
  },
  filter: {
    display: "flex",
    alignItems: "right",
    justifyContent: "right",
  },
  restLogo: {
    objectFit: "cover",
  },
  icons: {
    fontSize: "26px",
    color: "black",
    cursor: "pointer",
  },
}))

function TopBar({
  match,
  screen,
  setSearchItems,
  showOption,
  setShowOption,
  setTags,
  showTags,
  setShowTags,
  setTagsProgress,
}) {
  const classes = useStyles()
  let link = ""
  const [showSearch, setShowSearch] = useState(false)
  const [showLanguages, setShowLanguages] = useState(false)

  if (screen === "dish") {
    screen = "goMenu"
    link = `/restaurant/menu/${makeDecrypt("restaurantId")}`
  } else if (screen === "addPayment" || screen === "update") {
    link = `/checkout/${makeDecrypt("restaurantId")}`
  } else if (screen === "menu") {
    link = `/restaurant/${makeDecrypt("restaurantId")}/table/${makeDecrypt(
      "tableNo"
    )}`
  } else if (screen === "checkout") {
    link = `/restaurant/menu/${makeDecrypt("restaurantId")}`
  } else if (screen === "availableMenus") {
    link = `/restaurant/${makeDecrypt("restaurantId")}/table/${makeDecrypt(
      "tableNo"
    )}`
  }

  const restName = useSelector((state) => state?.restaurant?.restaurant?.name)

  const getItemsAsync = (key) => {
    axios
      .get(`/menu/${makeDecrypt("restaurantId")}/search-menu-item?name=${key}`)
      .then((response) => {
        return response.data
      })
      .then((results) => {
        setSearchItems(results)
      })
  }

  const getTags = () => {
    setShowOption(false)
    setShowTags(!showTags)
    let url = `http://localhost:3002/api/menu/${makeDecrypt(
      "restaurantId"
    )}/get-item-tags`
    axios
      .get(`/menu/${makeDecrypt("restaurantId")}/get-item-tags`, {
        onDownloadProgress: (p) => {
          setTagsProgress((p.loaded / p.total) * 100)
        },
      })
      .then((response) => {
        setTags(response.data)
      })
      .then((results) => {})
  }

  const handleKeyChange = (e) => {
    if (e.target.value.length > 2) getItemsAsync(e.target.value)
    else setSearchItems([])
  }

  const theme = createTheme({
    transitions: {
      easing: {
        easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
        easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
        easeIn: "cubic-bezier(0.97,0.5,0.28,0.75)",
        sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
      },
    },
  })
  return (
    <>
      {!showSearch ? (
        <Grid container className={classes.container}>
          <Grid item xs={2} className={classes.IconsHeader}>
            <Box display={"flex"} justifyContent="space-between">
              <Link to={link}>
                <KeyboardBackspaceIcon className={classes.icons} />
              </Link>
              {/* {screen === "menu" ? (
                <Box>
                  <TranslateIcon onClick={() => setShowLanguages(true)} />
                </Box>
              ) : (
                ""
              )} */}
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box
              sx={{ mb: 0.5 }}
              display="flex"
              alignItems={"center"}
              justifyContent="center"
            >
              {restName ? (
                <Typography variant="h4" align="center">
                  {restName}
                </Typography>
              ) : (
                <Typography>
                  <Skeleton variant="rect" width={"80px"} />
                </Typography>
              )}
            </Box>
            <Box className={classes.menu}>
              <Typography
                style={{ fontSize: "9px", color: "black", textAlign: "center" }}
                variant="body2"
              >
                Powered by DINIFY
              </Typography>
            </Box>
          </Grid>
          {screen === "menu" && (
            <Grid item xs={2}>
              <Box className={classes.filter}>
                <SearchIcon
                  className={classes.icons}
                  onClick={() => setShowSearch(true)}
                  style={{ marginRight: "7px" }}
                />
                <TuneIcon className={classes.icons} onClick={getTags} />
              </Box>
            </Grid>
          )}
        </Grid>
      ) : (
        <Slide
          direction="left"
          in={showSearch}
          mountOnEnter
          unmountOnExit
          easing={theme.transitions.easing.easeIn}
          timeout={400}
        >
          <Box className={classes.container}>
            {screen === "menu" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mb: 0.8,
                }}
              >
                <Box width="100%">
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search Items"
                    onChange={handleKeyChange}
                    className={classes.search}
                    id="searchBox"
                    onFocus={() => setShowOption(false)}
                    onBlur={() => setShowOption(true)}
                    InputProps={{
                      className: classes.input,
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box className={classes.filter}>
                  <CloseIcon
                    className={classes.icons}
                    onClick={() => {
                      setShowSearch(false)
                      setSearchItems([])
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Slide>
      )}
      <Dialog
        open={showLanguages}
        onClose={() => setShowLanguages(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Typography variant="h6"> Set primary language</Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {supportedLangs.map((language) => {
              return (
                <ListItem
                  onClick={() => {
                    makeEncrypt("primaryLang", language.languageCode)
                    setShowLanguages(false)
                  }}
                  key={language.name}
                >
                  <ListItemText primary={language.name} />
                </ListItem>
              )
            })}
          </List>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TopBar
