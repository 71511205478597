import { Box, Button, Typography } from "@material-ui/core"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import { makeStyles } from "@material-ui/styles"
import React, { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import ReferralImg from "../../images/referral.png"

const useStyles = makeStyles((theme) => ({
  imgBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  heading: {
    fontSize: "18px",
    textAlign: "center",
    marginTop: "20px",
  },
  para: {
    textAlign: "center",
    marginTop: "10px",
  },
  codeBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 20px",
    marginTop: "40px",
    border: "1px dashed black",
    backgroundColor: "#fff9f9",
    borderRadius: "5px",
  },
  copyBtn: {
    "&:focus": {
      outline: 0,
      border: 0,
    },
  },
}))

export default function Referral({ user }) {
  const classes = useStyles()

  return (
    <div>
      <Box className={classes.imgBox}>
        <img src={ReferralImg} width="300px" height="200px" alt="Dinify referral" />
      </Box>
      <Typography variant="h6" className={classes.heading}>
        Invite your friends and get $1 per order they place
      </Typography>
      <Typography variant="body2" className={classes.para}>
        Share the code below or ask them to enter it during they signup. Earn when
        your friends place orders on our app
      </Typography>
      <Box className={classes.codeBox}>
        <CopyToClipboard
          text={user.referralCode}
          onCopy={() => console.log("random")}
        >
          <Button
            className={classes.copyBtn}
            endIcon={<FileCopyIcon style={{ marginRight: "10px" }} />}
          >
            Copy
          </Button>
        </CopyToClipboard>
        <Button>{user.referralCode}</Button>
      </Box>
    </div>
  )
}
