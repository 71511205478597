import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import axios from "../../axios"

export default function UpcomingRewards({ totalOrders }) {
  const [steps, setSteps] = useState()
  const [currentStep, setCurrentStep] = useState()

  useEffect(() => {
    totalOrders && setCurrentStep(totalOrders / 20 - 1)
  }, [totalOrders])

  useEffect(() => {
    axios
      .get("/rewards/get-rewards")
      .then((res) => {
        setSteps(res.data)
      })
      .catch((error) => console.log(error))
  }, [])

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6"> Upcoming rewards</Typography>
      <Typography> Move forward, earn rewards!</Typography>
      <Stepper activeStep={currentStep} orientation="vertical">
        {steps?.length &&
          steps.map((step, index) => (
            <Step key={step}>
              <StepLabel>{step.description}</StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
              </StepContent>
            </Step>
          ))}
      </Stepper>
    </Box>
  )
}
