import { Box, Typography } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { makeStyles } from "@material-ui/styles"
import React from "react"

const useStyles = makeStyles((theme) => ({
  btnCont: {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
  },
  btn: {
    borderRadius: "5px",
  },
}))

function LoadingCheckoutScreen() {
  const classes = useStyles()
  return (
    <Box sx={{ p: 2, position: "relative" }}>
      <Typography variant="h4">Order Items</Typography>
      <Box sx={{ my: 2 }} display={"flex"} justifyContent="space-between">
        <Skeleton className={classes.btn} variant="rect" width={120} height={40} />
        <Skeleton className={classes.btn} variant="rect" width={120} height={40} />
      </Box>
      <Skeleton variant="rect" height={90} />
      <Typography style={{ marginTop: "20px" }} variant="h4">
        Totals
      </Typography>
      <Skeleton variant="rect" height={60} />
      <Box sx={{ my: 2, ml: "auto", width: "fit-content" }}>
        <Skeleton variant="rect" width={100} height={30} />
      </Box>
      <Box sx={{ mx: 2 }}>
        <Skeleton variant="rect" height={50} />
        <Skeleton style={{ marginTop: "10px" }} variant="rect" height={50} />
        <Skeleton style={{ marginTop: "10px" }} variant="rect" height={50} />
        <Skeleton style={{ marginTop: "10px" }} variant="rect" height={50} />
        <Skeleton style={{ marginTop: "10px" }} variant="rect" height={50} />
      </Box>
      <Box
        sx={{
          mt: 2,
          p: 2,
          backgroundColor: "white",
          boxShadow:
            "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;",
        }}
        className={classes.btnCont}
      >
        <Skeleton
          style={{
            margin: "auto",
            borderRadius: "10px",
            boxShadow:
              "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;",
          }}
          variant="rect"
          width={300}
          height={50}
        />
      </Box>
    </Box>
  )
}

export default LoadingCheckoutScreen
