import { Skeleton } from "@material-ui/lab"
import React from "react"

function MenuItemsSkeleton() {
  return (
    <div>
      <Skeleton variant="text" animation="wave" height={40} width="100px" />
      <Skeleton
        variant="rect"
        width="100%"
        height={94}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={94}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={94}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton animation="wave" variant="text" height={40} width="100px" />
      <Skeleton
        variant="rect"
        width="100%"
        height={94}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={94}
        style={{ marginBottom: "10px" }}
      />
      <Skeleton
        variant="rect"
        width="100%"
        height={94}
        style={{ marginBottom: "10px" }}
      />
    </div>
  )
}

export default MenuItemsSkeleton
