import { Box, Typography } from "@material-ui/core"
import React from "react"

export default function AchievedRewards() {
  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6">My Rewards</Typography>
      <Typography>Place at least 5 orders to get exciting rewards</Typography>
    </Box>
  )
}
