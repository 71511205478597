import { Box, Button, makeStyles, Typography } from "@material-ui/core"
import { CardElement, Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { setRestaurant } from "../actions/restaurantActions"
import { setPaymentMethodId } from "../actions/stripeActions"
import { setCurrentUser } from "../actions/userActions"
import axios from "../axios"
import CouponHighlighter from "../components/CouponHighlighter"
import PaymentAccordion from "../components/PaymentAccordion"
import PciBox from "../components/PciBox"
import TopBar from "../components/TopBar"
import { makeDecrypt, makeEncrypt } from "../utils/securels"
/* global Stripe */
var stripe = Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const useStyles = makeStyles((theme) => ({
  card: {
    marginRight: "10px",
  },
  alert: {
    display: "flex",
    width: "90%",
    color: theme.palette.primary.main,
    padding: theme.spacing(2),
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  success: {
    display: "flex",
    width: "90%",
    color: "green",
    padding: theme.spacing(2),
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translate(-50%, 0)",
  },
  cardWrapper: {
    padding: "10px 5px",
    borderRadius: "5px",
  },
  cardContainer: {
    padding: "10px 5px",
    width: "100% !important",
  },
  proceedBtn: {
    position: "absolute",
    bottom: "20px",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))

function AddPaymentMethod() {
  const classes = useStyles()
  const elements = stripe.elements()
  const card = elements.create("card")

  const [alertMessage, setAlertMessage] = useState("")
  let complete = false
  const dispatch = useDispatch()
  const { restaurant } = useSelector((state) => state.restaurant)
  const [values, setValues] = useState({
    cardId: "",
    lastDigits: "",
    setupCS: "",
    paymentMethodId: "",
    isAddedSuccess: false,
    setupIntentId: "",
    customerId: "",
    expMonth: "",
    expYear: "",
  })
  const {
    cardId,
    lastDigits,
    customerId,
    paymentMethodId,
    isAddedSuccess,
    expMonth,
    expYear,
  } = values
  const [prevLast4, setPrevLast4] = useState("")
  const [prevPaymentIntentId, setPrevPaymentIntentId] = useState("")
  const [prevPaymentMethodId, setPrevPaymentMethodId] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cardElement, setCardElement] = useState(null)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [isCardAdded, setIsCardAdded] = useState()
  const [successMessage, setSuccessMessage] = useState()
  const [prevNativePaymentMethodId, setPrevNativePaymentMethodId] = useState(null)
  const [prevNativeCardLastDigits, setPrevNativeCardLastDigits] = useState("")
  const [prevNativeType, setPrevNativeType] = useState("")
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("")

  // useEffect(() => {
  //   console.log(makeDecrypt("paymentMethodId"), selectedPaymentMethod)
  //   console.log(Boolean(makeDecrypt("paymentMethodId") === selectedPaymentMethod))
  //   makeDecrypt("paymentMethodId") === selectedPaymentMethod && selectedPaymentMethod
  //     ? setIsCardAdded(true)
  //     : setIsCardAdded(false)
  // }, [isLoading, selectedPaymentMethod])

  // useEffect(() => {
  //   if (isAddedSuccess) {
  //     setPrevLast4(lastDigits)
  //     axios
  //       .post(`/user/${makeDecrypt("userId")}/add-payment-method`, {
  //         expMonth: expMonth < 10 ? "0" + expMonth : expMonth,
  //         expYear: expYear,
  //         lastDigits: lastDigits,
  //         paymentMethodId: paymentMethodId,
  //         paymentId: cardId,
  //         setupIntentId: "",
  //         customerId: customerId,
  //         phone: makeDecrypt("user"),
  //         email: makeDecrypt("email"),
  //         paymentIntentId: prevPaymentIntentId
  //           ? prevPaymentIntentId
  //           : makeDecrypt("paymentIntentId"),
  //       })
  //       .then((response) => {
  //         setAlertMessage(response.data.message)
  //         alert("yes call")
  //       })
  //       .catch((error) => {
  //         setAlertMessage(error.message)
  //       })
  //   }
  // }, [values])

  useEffect(() => {
    getUser()
    setCardElement(
      <Box
        style={{
          opacity: `${isCardAdded ? 0 : 1}`,
        }}
        className={classes.cardContainer}
      >
        <Typography variant="h3" style={{ marginBottom: "20px", fontSize: "17px" }}>
          Enter Card details
        </Typography>
        <Elements stripe={stripePromise}>
          <CardElement onReady={handleReady} />
        </Elements>
        <Button
          variant="outlined"
          color="black"
          size="small"
          onClick={(e) => {
            setIsLoading(true)
            handleSubmit(e)
          }}
          style={{ marginTop: "20px", borderColor: "black" }}
          fullWidth
        >
          Link Your Card
        </Button>
      </Box>
    )
  }, [isLoading, localStorage.getItem("CustomerId")])

  const getUser = () => {
    axios
      .post(`/user/user-details`, {
        phone: makeDecrypt("user"),
        userId: makeDecrypt("userId"),
      })
      .then((response) => {
        let stripeDetails = response.data.stripeDetails.paymentMethod
        setPrevLast4(stripeDetails.lastDigits)
        setPrevPaymentIntentId(stripeDetails.paymentIntentId)
        setPrevPaymentMethodId(stripeDetails.paymentMethodId)
        setPrevNativePaymentMethodId(stripeDetails.nativePaymentMethodId)
        setPrevNativeCardLastDigits(stripeDetails.nativeCardLastDigits)
        setPrevNativeType(stripeDetails.nativeType)
        if (!makeDecrypt("CustomerId"))
          makeEncrypt("CustomerId", response.data.stripeDetails.customerId)

        if (!response.data.stripeDetails.customerId && makeDecrypt("CustomerId"))
          localStorage.removeItem("CustomerId")

        if (!stripeDetails.nativePaymentMethodId && !stripeDetails.paymentMethodId)
          localStorage.removeItem("paymentMethodId")

        if (!restaurant) dispatch(setRestaurant(makeDecrypt("restaurantId")))
      })
      .catch((error) => {
        setAlertMessage(error.message)
      })
  }

  useEffect(() => {
    if (
      makeDecrypt("paymentMethodId") &&
      (prevPaymentMethodId || prevNativePaymentMethodId)
    ) {
      if (
        prevNativePaymentMethodId === makeDecrypt("paymentMethodId") &&
        prevNativePaymentMethodId
      ) {
        setSelectedPaymentMethod(prevNativePaymentMethodId)
        if (prevPaymentMethodId) setIsCardAdded(true)
      } else if (
        prevPaymentMethodId === makeDecrypt("paymentMethodId") &&
        prevPaymentMethodId
      ) {
        setSelectedPaymentMethod(prevPaymentMethodId)
        setIsCardAdded(true)
      }
    } else if (
      (!makeDecrypt("paymentMethodId") && prevPaymentMethodId) ||
      prevNativePaymentMethodId
    ) {
      if (prevPaymentMethodId) {
        setSelectedPaymentMethod(prevPaymentMethodId)
        makeEncrypt("paymentMethodId", prevPaymentMethodId)
        setIsCardAdded(true)
      } else if (prevNativePaymentMethodId) {
        setSelectedPaymentMethod(prevNativePaymentMethodId)
        makeEncrypt("paymentMethodId", prevNativePaymentMethodId)
        setIsCardAdded(false)
      }
    }
  }, [prevPaymentMethodId, prevNativePaymentMethodId])

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
    }, 6000)
  }, [alertMessage])
  useEffect(() => {
    setTimeout(() => {
      setSuccessMessage("")
    }, 6000)
  }, [successMessage])

  const createCustomerId = async (email) => {
    return await axios
      .post("/stripe/newCustomer", { email })
      .then((res) => {
        return res.data.customerId
      })
      .catch((error) => {
        setAlertMessage(error.message)
      })
  }
  const attachPaymentMethodIdToCustomer = async (
    thisPaymentMethodId,
    thisCustomerId
  ) => {
    return await axios
      .post("/stripe/attach-payment-method", {
        paymentMethodId: thisPaymentMethodId,
        customerId: thisCustomerId,
      })
      .then((data) => data)
      .catch((error) => {
        setAlertMessage(error.message)
      })
  }

  const detachPaymentMethodIdFromCustomer = async (thisPaymentMethodId) => {
    return await axios
      .post("/stripe/detach-payment-method", {
        paymentMethodId: thisPaymentMethodId,
      })
      .then((data) => data)
      .catch((error) => {
        console.log(error)
        setAlertMessage(error.message)
      })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let email = makeDecrypt("email")
    let thisCustomerId
    if (!makeDecrypt("CustomerId")) {
      thisCustomerId = await createCustomerId(email)
      makeEncrypt("CustomerId", thisCustomerId)
    } else thisCustomerId = makeDecrypt("CustomerId")
    setValues((prevValues) => ({
      ...prevValues,
      customerId: thisCustomerId,
    }))
    stripe
      .createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {
          email: email,
        },
      })
      .then(function (result) {
        dispatch(
          setCurrentUser({
            CustomerId: makeDecrypt("CustomerId"),
            lastDigits: result.paymentMethod.card.last4,
            email: email,
            phone: makeDecrypt("user"),
            cardId: result.paymentMethod.created,
          })
        )
        attachPaymentMethodIdToCustomer(result.paymentMethod.id, thisCustomerId)
          .then((data) => {
            setValues((prevValues) => ({
              ...prevValues,
              paymentMethodId: data.data.id,
            }))
            dispatch(setPaymentMethodId(data.data.id))
            if (thisCustomerId) {
              axios
                .post(`/user/${makeDecrypt("userId")}/add-payment-method`, {
                  expMonth:
                    result.paymentMethod.card.exp_month < 10
                      ? "0" + result.paymentMethod.card.exp_month
                      : result.paymentMethod.card.exp_month,
                  expYear: result.paymentMethod.card.exp_year,
                  lastDigits: result.paymentMethod.card.last4,
                  paymentMethodId: data.data.id,
                  paymentId: result.paymentMethod.created,
                  setupIntentId: "",
                  customerId: thisCustomerId,
                  phone: makeDecrypt("user"),
                  email: makeDecrypt("email"),
                  paymentIntentId: prevPaymentIntentId
                    ? prevPaymentIntentId
                    : makeDecrypt("paymentIntentId"),
                })
                .then((response) => {
                  if (!makeDecrypt("paymentMethodId"))
                    makeEncrypt("paymentMethodId", data.data.id)
                  setSuccessMessage("Card added successfully!")
                })
                .catch((error) => {
                  setAlertMessage(error.response.data.raw.message)
                  setIsCardAdded(false)
                  setIsLoading(false)
                  setCardElement()
                  changeCardElement()
                })
            }
            if (makeDecrypt("CustomerId")) {
              setValues((prevValues) => ({
                ...prevValues,
                isAddedSuccess: true,
              }))
            }
            setSuccessMessage("Card added successfully!")
            window.location.reload()
            setIsLoading(false)
          })
          .catch((error) => {
            setAlertMessage("Something went wrong")
            setIsCardAdded(false)
            setIsLoading(false)
            setCardElement()
            changeCardElement()
          })
      })
      .catch((error) => {
        setIsLoading(false)
        setIsCardAdded(false)
        changeCardElement()
      })
  }

  const changeCardElement = () => {
    setCardElement(
      <Box
        style={{
          padding: "20px 0",
          opacity: `${isCardAdded ? 0 : 1}`,
        }}
        className={classes.cardContainer}
      >
        <Typography variant="h3" style={{ marginBottom: "20px", fontSize: "17px" }}>
          Enter Card details
        </Typography>
        <Elements stripe={stripePromise}>
          <CardElement onReady={handleReady} />
        </Elements>
        <Button
          variant="outlined"
          color="black"
          size="small"
          onClick={(e) => {
            setIsLoading(true)
            handleSubmit(e)
          }}
          style={{ marginTop: "20px", borderColor: "black" }}
          fullWidth
        >
          Link Your Card
        </Button>
      </Box>
    )
    setValues((prevValues) => ({
      ...prevValues,
      cardId: "",
      lastDigits: "",
      setupCS: "",
      paymentMethodId: "",
      isAddedSuccess: false,
      setupIntentId: "",
      customerId: "",
      expMonth: "",
      expYear: "",
    }))
  }

  card.on("change", function (event) {
    setAlertMessage("")
    if (event.complete) {
      complete = true
    } else {
      complete = false
    }
    if (event.error) {
      setAlertMessage(event.error.message)
    }
  })

  const handleReady = (e) => {
    card.mount(".StripeElement")
  }

  const handleIsDelete = () => {
    detachPaymentMethodIdFromCustomer(prevPaymentMethodId)
      .then((data) => {
        axios
          .put(`/user/${makeDecrypt("userId")}/delete-payment-method`, {
            phone: makeDecrypt("user"),
            isRemoveCustomerId: !prevNativePaymentMethodId,
          })
          .then((thisData) => {
            if (selectedPaymentMethod === makeDecrypt("paymentMethodId"))
              localStorage.removeItem("paymentMethodId")
            window.location.reload()
          })
          .catch((error) => {
            setAlertMessage(error.message)
          })
      })
      .catch((error) => {
        setAlertMessage(error.message)
      })
  }

  return (
    <Box style={{ width: "100%" }}>
      <CouponHighlighter />
      <TopBar screen="addPayment" />
      <Box style={{ width: "100%", height: "100%" }}>
        {alertMessage && (
          <Box className={classes.alert}>
            <Typography textAlign="center">{alertMessage}</Typography>
          </Box>
        )}
        {successMessage && (
          <Box className={classes.success}>
            <Typography textAlign="center">{successMessage}</Typography>
          </Box>
        )}
        <PciBox />
        <PaymentAccordion
          setSuccessMessage={setSuccessMessage}
          prevNativePaymentMethodId={prevNativePaymentMethodId}
          prevNativeCardLastDigits={prevNativeCardLastDigits}
          createCustomerId={createCustomerId}
          attachPaymentMethodIdToCustomer={attachPaymentMethodIdToCustomer}
          detachPaymentMethodIdFromCustomer={detachPaymentMethodIdFromCustomer}
          isCardAdded={isCardAdded}
          prevLast4={prevLast4}
          selectedPaymentMethod={selectedPaymentMethod}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
          setAlertMessage={setAlertMessage}
          prevPaymentMethodId={prevPaymentMethodId}
          deleteLoading={deleteLoading}
          setDeleteLoading={setDeleteLoading}
          handleIsDelete={handleIsDelete}
          isLoading={isLoading}
          cardElement={cardElement}
        />

        <Link
          to={`/checkout/${makeDecrypt("restaurantId")}`}
          style={{ color: "white" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            className={classes.proceedBtn}
          >
            PROCEED
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

export default AddPaymentMethod
